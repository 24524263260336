import React from 'react';
import {motion} from "framer-motion";

const ListLife = (props) => {
    const {hash} = props;
    const ert = hash.map((item)=>{
        return(
            <motion.li
            whileHover={{x:10}}
            className="list-hash d-flex mb-2 align-items-center">
               <span className="hash-life-club me-2"><i className="fa-solid fa-hashtag"></i></span>
               <span className="text-white fw-light">{item.name}</span>
            </motion.li>
        )
    })
    return (

        <div>
            {ert} 
        </div>
    );
}

export default ListLife;
